<template>
  <section>
    <v-row>
      <v-col class="d-flex justify-space-between flex-wrap">
        <h1>Servicios</h1>

        <v-btn
          color="primary"
          :to="{ name: 'product-form' }"
        >
          <v-icon left>mdi-plus</v-icon>
          Agregar
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          hide-details
          single-line
        />
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="options.itemsPerPage"
        :loading="loading"
        :options.sync="options"
        :search="search"
        :server-items-length="total"
      >
        <template v-slot:item.unit_price_normal="{ value }">
          {{ value | currency }}
        </template>

        <template v-slot:item.unit_price_urgent="{ value }">
          {{ value | currency }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                color="info"
                :to="{ name: 'product-detail', params: { uuid: item.uuid } }"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Detalle</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                color="warning"
                :to="{ name: 'product-form', params: { uuid: item.uuid } }"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                color="error"
                v-bind="attrs"
                v-on="on"
                @click="handleDelete(item)"
                small
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="dialog"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Eliminar</v-card-title>

        <v-card-text>
          ¿Desea eliminar el producto <b>{{ selectedItem.name | uppercase }}</b>?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="darken-1"
            text
            @click="dialog = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="remove"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import ProductAPI from '@/api/product';
import TableMixin from '@/mixins/TableMixin';

export default {
  name: 'ProductList',

  mixins: [
    TableMixin,
  ],

  data: () => ({
    headers: [
      { text: 'Servicio', value: 'service.name' },
      { text: 'Producto', value: 'name' },
      { text: 'Clave', value: 'key' },
      { text: 'Precio Normal', value: 'unit_price_normal' },
      { text: 'Precio Urgente', value: 'unit_price_urgent' },
      { text: 'Cantidad de prendas', value: 'clothes_quantity' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
  }),

  async created() {
    this.api = new ProductAPI();
    await this.list();
  },
};
</script>

<style lang="scss" scoped>

</style>
